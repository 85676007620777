import React from 'react'
import { Link } from "react-router-dom";
import {BsArrowRightShort, BsCheckCircleFill} from "react-icons/bs";
import Grid from "@mui/material/Grid";
import {LazyLoadImage} from "react-lazy-load-image-component";
import grandImg from "../assets/images/scp-img-granddesign.webp";
import newImg from "../assets/images/New_build.webp";
import renovationImg from "../assets/images/Extension.webp";
import "../assets/css/services.scss";

function ResidentialServices(props) {
    return (
        <div className="section builds gray" id="residential-services">
            <div className="container">
                <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={3}
                    className="builds-wrap"
                    data-aos="fade"
                >
                    <Grid item xs={12} sm={6} md={3} className="build">
                        <Link to="/residential/build-grand-designs" className="link-wrap">
                            <h3>Grand Designs</h3>
                            <p>
                                Build your own Grand Design! See the details of an amazing and
                                unique renovation and build SB2 were engaged for, and which
                                recently featured on Grand Designs NZ.
                            </p>
                            <LazyLoadImage
                                src={grandImg}
                                width="100px"
                                height="100px"
                                sizes="(max-width: 500px) 500px, 800px"
                                alt="Grand designs"
                            />
                            <span className="redButton btn link">Learn more <BsArrowRightShort/></span>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="build">
                        <Link to="/residential/house-builders-dunedin" className="link-wrap">
                            <h3>New Build</h3>
                            <p>
                                Whether it is your first home, your family home or your
                                forever home, the house builders at SB2 Build will deliver you
                                exactly what you are looking for - on time and on budget.
                            </p>
                            <LazyLoadImage
                                src={newImg}
                                width="100px"
                                height="100px"
                                sizes="(max-width: 500px) 500px, 800px"
                                alt="New build"
                            />
                            <span className="redButton btn link">Learn more <BsArrowRightShort/></span>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="build">
                        <Link to="/residential/home-renovations-dunedin" className="link-wrap">
                            <h3>
                                Renovation/ <br /> Extension
                            </h3>
                            <p>
                                Make your property match your lifestyle with home renovations
                                from SB2 Build. Upgrade key rooms with a kitchen remodel or
                                bathroom renovation or add space with an extension.
                            </p>
                            <LazyLoadImage
                                src={renovationImg}
                                width="100px"
                                height="100px"
                                sizes="(max-width: 500px) 500px, 800px"
                                alt="Renovation build"
                            />
                            <span className="redButton btn link">Learn more <BsArrowRightShort/></span>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

export default ResidentialServices