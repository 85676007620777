// pages/NotFound.jsx
import React from 'react';
import "../assets/css/global.scss";
import Banner from "../components/Banner";
import bannerBg from "../assets/images/sb2-form-bg.webp";

const NotFound = () => {
    return (
        <Banner
            header = "404 - Page Not Found"
            imgBg = {bannerBg}
        />
    );
};

export default NotFound;