import React from "react";
import HelmetTag from "../components/HelmetTag.jsx";
import Banner from "../components/Banner.jsx";
import Ctaform from "../components/CtaForm.jsx";
import Testimonials from "../components/TestimonialsSlider.jsx";
import AllBlog from "../components/AllBlog.jsx";
import ResidentialServices from "../components/ResidentialServices.jsx";
import { RiDoubleQuotesR } from "react-icons/ri";
import bannerBg from "../assets/images/New_build.jpg";
import PhotoGallery from "../components/PhotoGallery";

const Residential = () => {
    document.body.classList.remove(
        "blog",
        "main-blog",
        "single-blog",
        "home",
        "contact",
        "about",
        "testimonial",
        "enquire",
        "default-page"
    );
    document.body.classList.add("landing");

    return (
        <div className="residential-content landing">
            <HelmetTag
                pageTitle="Dunedin Residential | Home Builds Otago NZ"
                title="Dunedin Residential | Home Builds Otago NZ"
                robots="index"
                description="For top-notch new home builds in Dunedin, New Zealand, contact SB2 Build at +64274797532. Skilled, reliable services guaranteed."
                ogTitle="Dunedin New Builds | Home Builds Otago NZ"
                ogDescription="For top-notch new home builds in Dunedin, New Zealand, contact SB2 Build at +64274797532. Skilled, reliable services guaranteed."
                locale="en_NZ"
                type="website"
                updated_time=""
                image=""
                imgsecure_url=""
                imgwidth=""
                imgheight=""
                imgalt=""
                imgtype=""
                artpublished_time=""
                artmodified_time=""
                twcard=""
                twtitle="Residential Dunedin | Top House Builders Mosgiel"
                twdescription="Discover quality new build homes in Dunedin crafted by SB2 Build. Our experienced house builders deliver exceptional craftsmanship and personalised designs."
                twimage=""
                twlabel1=""
                twdata1=""
                twlabel2=""
                twdata2=""
                canonical="https://sb2build.co.nz/residential"

            />
            <Banner
                header = "Residential Services"
                imgBg = {bannerBg}
                pageName = "New Builds"
                pageLink = "/house-builders-dunedin"
            />

            <ResidentialServices></ResidentialServices>

            <PhotoGallery></PhotoGallery>

            <div className="section testimonial darkblue">
                <div className="container center">
                    <h2 className="center">Testimonials</h2>
                    <RiDoubleQuotesR />
                    <Testimonials/>
                </div>
            </div>
            <div className="section cta">
                <div className="container max1000">
                    <h2 className="blueText">Make an <span>Enquiry</span></h2>
                    <p>Please fill in the form and we will be in contact with you within the same business day.</p>
                    <Ctaform />
                </div>
            </div>

            <div className="section latest-blog">
                <div className="container">
                    <h2 className="center blueText">Latest <span>Blogs</span></h2>
                    <AllBlog categoryFilter="all" limit={2} />
                </div>
            </div>
        </div>
    );
}

export default Residential
