// SlickSlider.jsx
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "../assets/css/slick-slider.scss";

const resizeImage = (src, maxWidth, maxHeight, callback) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }

        const scaleFactor = 2; // Adjust this factor as needed for higher resolution
        const canvas = document.createElement('canvas');
        canvas.width = width * scaleFactor;
        canvas.height = height * scaleFactor;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(callback, 'image/jpeg', 0.95); // Quality set to 0.95
    };
};

const SlickSlider = ({ images }) => {
    const [resizedImages, setResizedImages] = useState([]);

    useEffect(() => {
        const maxWidth = 500; // Set your desired max width
        const maxHeight = 300; // Set your desired max height

        const resizePromises = images.map((image) =>
            new Promise((resolve) => {
                resizeImage(image.src, maxWidth, maxHeight, (blob) => {
                    const resizedImageURL = URL.createObjectURL(blob);
                    resolve({ ...image, src: resizedImageURL });
                });
            })
        );

        Promise.all(resizePromises).then((resized) => setResizedImages(resized));
    }, [images]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div style={{ width: '100%', overflow: 'hidden' }}>
            <Slider {...settings}>
                {resizedImages.map((image, index) => (
                    <div key={index} style={{ padding: '0 10px' }}>
                        <img src={image.src} alt={image.alt} style={{ width: '100%', height: 'auto' }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SlickSlider;