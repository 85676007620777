import {React,useState } from 'react';
import { Grid, TextField, Alert} from '@mui/material';
import { BsChatQuoteFill } from "react-icons/bs";
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
export default function CtaForm() {
    
  const [alert, setAlert] = useState(false);
  const [open, setOpen] = useState(true);

  const submitHandler = async (e) => {
    e.preventDefault();
    const myForm = e.target;
    const formData = new FormData(myForm);
    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        setAlert(true);
        document.getElementById('contact-form').reset()
      }
      )
      .catch((error) => alert(error));

  };

  return (
        <div className='component max1000 ctaform'>
           {alert ? <Collapse in={open}> <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Thank you for your Enquiry we will get back with you as soon as possible
        </Alert></Collapse>: <></>}
        <br />
        <form method='POST' name="contact" id="contact-form" onSubmit={submitHandler}  >
         <input type="hidden" name="form-name" value="contact" />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} className="input_group" >
                    <TextField type="text" required className="form-control" id="name" name="firstname" placeholder='First Name' aria-describedby="first_name" />
                </Grid>
                <Grid item sm={6} xs={12} className="input_group" >
                    <TextField type="text" required className="form-control" id="last_name"  name="lastname" placeholder='Last Name' aria-describedby="last_name" />
                </Grid>
                <Grid item sm={6} xs={12} className="input_group" >
                    <TextField type="email" required className="form-control" id="email"  name="email" placeholder='Email' aria-describedby="Email" />
                </Grid>
                <Grid item sm={6} xs={12} className="input_group" >
                    <TextField type="text" required className="form-control" id="phonenumber" name="phone"  placeholder='Phone Number' aria-describedby="Phonenumber" />
                </Grid>
                <Grid item xs={12} className="input_group" >
                    <TextField multiline rows={4} required className='form-control' id='help' name="message" placeholder='How can we help you?'  aria-describedby='help' />
                </Grid>
                <Grid item xs={12} className="input_group" >
                    <button type='submit' className='redButton btn right'>Submit</button>
                </Grid>
            </Grid>
        </form>
        
        <BsChatQuoteFill className='bg tr'/>
    </div>
    
  );
}