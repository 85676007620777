import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from '../assets/sb2-build-logo.webp';
import { FaPhoneAlt, FaBars } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function Header() {

  const [showNav, setShowNav] = useState(false)
  const handleShowNav = () => {
    setShowNav(!showNav)
  }

  return (
    <header>
      <div className="main-bar">
        <div className="main-content">
          <div className="navbar">
            <div className="nav-left">
              <Link to="/"><LazyLoadImage src={Logo} width="229px" height="80px" alt="Sb2build Logo" className="logo"/></Link>
            </div>
            <div className="nav-right">
                <nav className={`${showNav && 'active'}`}>
                    <ul className="main-nav parent">
                        <li onClick={handleShowNav}>
                            <Link to="/">Home</Link>
                        </li>
                        <li onClick={handleShowNav} className="has-submenu about">
                            <Link to="/home-building-companies-dunedin">About</Link>
                            <ul className="submenu">
                              <li onClick={handleShowNav}>
                                <Link to="/recommended-builder-dunedin">Testimonials</Link>
                              </li>
                            </ul>
                        </li>
                        <li onClick={handleShowNav} className="has-submenu services">
                            <Link to="/residential">Residential</Link>
                            <ul className="submenu">
                              <li onClick={handleShowNav}>
                                <Link to="/residential/build-grand-designs">Grand Designs</Link>
                              </li>
                              <li onClick={handleShowNav}>
                                <Link to="/residential/house-builders-dunedin">New Builds</Link>
                              </li>
                              <li onClick={handleShowNav}>
                                <Link to="/residential/home-renovations-dunedin">Renovations</Link>
                              </li>
                            </ul>
                        </li>
                        <li onClick={handleShowNav} className="has-submenu services">
                            <Link to="/commercial">Commercial</Link>
                            <ul className="submenu">
                                <li onClick={handleShowNav}>
                                    <Link to="/commercial/light-commercial-builder-dunedin">Light Commercial</Link>
                                </li>
                                <li onClick={handleShowNav}>
                                    <Link to="/commercial/structural-upgrades-dunedin">Structural Upgrades</Link>
                                </li>
                            </ul>
                        </li>
                        <li onClick={handleShowNav}>
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li onClick={handleShowNav}>
                            <Link to="/bathroom-renovations-dunedin">Enquire</Link>
                        </li>
                        <li onClick={handleShowNav}>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                    </ul>
                </nav>
                <a href={"tel:+64274797532"} className="redButton btn"><FaPhoneAlt/> 027 479 7532 </a>
                <div onClick={handleShowNav} className="burger-menu mobile"> <FaBars/> </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}