import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../client';
import { Link } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';
import Banner from "./Banner";
import HelmetTag from "./HelmetTag";
import AllBlog from "./AllBlog";
import Benefits from "./Benefits";
import {BsCheckCircleFill} from "react-icons/bs";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

import beforeImg from "../assets/images/before-test.jpg"
import afterImg from "../assets/images/after-test.jpg"

const FIRST_IMAGE = {
    imageUrl: afterImg
};
const SECOND_IMAGE = {
    imageUrl: beforeImg
};


export default function Service() {
    const [serviceData, setService] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[slug.current == $slug][0]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          sections[]{
            _type == 'sectionWithImage' => {
              text,
              image{
                asset->{
                  _id,
                  url
                },
                alt
              },
              imagePosition
            }
          },
          body,
          intro
        }`,
                { slug }
            )
            .then((data) => {
                setService(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError('Failed to fetch service data');
                setLoading(false);
            });
    }, [slug]);

    if (loading) return <div className="spinner">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    const canonicalUrl = window.location.href;

    return (
        <div className="landing">
            <HelmetTag
                pageTitle="Experienced Commercial Builders in Dunedin | SB2 Build"
                title="Experienced Commercial Builders in Dunedin | SB2 Build"
                robots="index"
                description="Top commercial builders in Dunedin providing expert construction services for your projects. Trust us for exceptional results in commercial construction."
                ogTitle="Experienced Commercial Builders in Dunedin | SB2 Build"
                ogDescription="Top commercial builders in Dunedin providing expert construction services for your projects. Trust us for exceptional results in commercial construction."
                locale="en_NZ"
                type="website"
                canonical={canonicalUrl}
            />
            {serviceData && (
                <Banner
                    header={serviceData.title}
                    imgBg={serviceData.mainImage.asset.url}
                    pageName={serviceData.title}
                    pageLink={serviceData.url}
                />
            )}
            {serviceData?.intro && (
                <div className="section">
                    <div className="container intro-text">
                        <BlockContent blocks={serviceData.intro} />
                    </div>
                </div>
            )}
            {serviceData?.title === "Structural Upgrades for Homes and Commercial Establishments" && (
                <div className="benefits align-midcenter">
                    <ul className="check">
                        <li><BsCheckCircleFill/>Strengthen existing homes and commercial buildings</li>
                        <li><BsCheckCircleFill/>Protect your property and its occupants in case of earthquakes</li>
                        <li><BsCheckCircleFill/>Comply with industry standards</li>
                    </ul>
                    <p>Contact SB2 Build today for structural upgrades for your home or business.</p>
                    <a href={"/contact-us"} className="redButton btn"> Contact Us Now </a>
                </div>
                )}
            <div className="mx-auto">
                <div>
                    {serviceData?.sections?.length > 0 && (
                        <div className="sectionWithImage">
                            {serviceData.sections.map((section, index) => (
                                <div key={index} className={`${section.imagePosition} section flex mb-20`} style={{ display: 'flex', flexDirection: section.imagePosition === 'left' ? 'row-reverse' : 'row' }}>
                                    <div style={{ flex: 1 }}>
                                        <BlockContent blocks={section.text} />
                                    </div>
                                    {section.image && (
                                        <div style={{ flex: 1 }}>
                                            <img
                                                src={section.image.asset.url}
                                                alt={section.image.alt}
                                                style={{ maxWidth: '100%' }}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                    {/*<div className="before-after-container">*/}
                    {/*    <ReactBeforeSliderComponent*/}
                    {/*        firstImage={FIRST_IMAGE}*/}
                    {/*        secondImage={SECOND_IMAGE}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {serviceData?.body && (
                        <div className="section service-body">
                            <div className="container">
                                <BlockContent blocks={serviceData.body} />
                            </div>
                        </div>
                    )}
                    <div className="section latest-blog gray">
                        <div className="container">
                            <h2 className="center blueText">Latest <span>Blog</span></h2>
                            <AllBlog categoryFilter="all" limit={2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}