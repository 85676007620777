import { useState } from "react";

import React from 'react'
import "../assets/css/photo-gallery.scss";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// https://github.com/igordanchenko/react-photo-album/tree/main

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";


import galleryimg1 from "../assets/images/gallery/bathroom1.jpeg";
import galleryimg2 from "../assets/images/gallery/lounge1.jpg";
import galleryimg3 from "../assets/images/gallery/residential1.jpg";
import galleryimg4 from "../assets/images/gallery/residential2.jpg";
import galleryimg5 from "../assets/images/gallery/bathroom2.jpg";
import galleryimg6 from "../assets/images/gallery/bathroom3.jpg";
import galleryimg7 from "../assets/images/gallery/deck1.jpg";
import galleryimg8 from "../assets/images/gallery/deck2.jpg";
import galleryimg9 from "../assets/images/gallery/doorway.jpg";
import galleryimg10 from "../assets/images/gallery/kitchen1.jpg";
import galleryimg11 from "../assets/images/gallery/new-build1.jpg";
import galleryimg12 from "../assets/images/gallery/new-build2.jpg";
import galleryimg13 from "../assets/images/gallery/new-build2.jpg";
import galleryimg14 from "../assets/images/gallery/residential3.jpg";
import galleryimg15 from "../assets/images/gallery/residential4.jpg";
import galleryimg16 from "../assets/images/gallery/standing-deck.jpg";


const photos = [
    { src: galleryimg1, width: 752, height: 560, alt: "Modern style kitchen with dim lighting" },
    { src: galleryimg3, width: 1200, height: 1600, alt: "Tiny House under Construction" },
    { src: galleryimg4, width: 1600, height: 757, alt: "Two men working on House exterior" },
    { src: galleryimg2, width: 1600, height: 1200, alt: "Lounge" },
    { src: galleryimg5, width: 1600, height: 1200, alt: "Bathroom with white tiles" },
    { src: galleryimg6, width: 1600, height: 1200, alt: "Modern Style Bathroom" },
    { src: galleryimg7, width: 1600, height: 900, alt: "Deck area with seating" },
    { src: galleryimg8, width: 1600, height: 900, alt: "Wooden deck under construction" },
    { src: galleryimg9, width: 640, height: 480, alt: "Doorway" },
    { src: galleryimg16, width: 578, height: 1029, alt: "Standing Deck" },
    { src: galleryimg10, width: 1600, height: 1200, alt: "Young Girl Sitting in the kitchen" },
    { src: galleryimg11, width: 1600, height: 1200, alt: "Top View of a New build home under Construction" },
    { src: galleryimg12, width: 1600, height: 1200, alt: "New build home under Construction" },
    { src: galleryimg13, width: 1600, height: 1200, alt: "New build home under Construction" },
    { src: galleryimg14, width: 1600, height: 1200, alt: "House under construction" },
    { src: galleryimg15, width: 1829, height: 1372, alt: "Building exterior Wooden Roof" },

];

export default function PhotoGallery() {
    const [index, setIndex] = useState(-1);

    return (
        <>
            <PhotoAlbum photos={photos} layout="rows" onClick={({ index }) => setIndex(index)} />

            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </>
    );
}