import React from 'react'
import HelmetTag from '../components/HelmetTag';
import Banner from '../components/Banner';
import bannerBg from "../assets/images/sb2-form-bg.webp";
import Grid from "@mui/material/Grid"
export default function TermsOfServicePage() {
  return (
    <div className='tos-content landing'>
        <HelmetTag 
            pageTitle="Terms of Service | SB2 Build"
            title="Terms of Service | SB2 Build"
            robots="index"
            description="Looking for a highly recommended builder in Dunedin? Trust SB2 Build for exceptional craftsmanship and reliable service. Contact us for your building needs.'"
            ogTitle="Terms of Service | SB2 Build"
            ogDescription="Looking for a highly recommended builder in Dunedin? Trust SB2 Build for exceptional craftsmanship and reliable service. Contact us for your building needs.'"
            locale=""
            type=""
            updated_time=""
            image=""
            imgsecure_url=""
            imgwidth=""
            imgheight=""
            imgalt=""
            imgtype=""
            artpublished_time=""
            artmodified_time=""
            twcard=""
            twtitle="Top Rated Builder in Dunedin & Mosgiel | SB2 Build"
            twdescription="Looking for a highly recommended builder in Dunedin? Trust SB2 Build for exceptional craftsmanship and reliable service. Contact us for your building needs.'"
            twimage=""
            twlabel1=""
            twdata1=""
            twlabel2=""
            twdata2=""
            canonical="https://sb2build.co.nz/terms-of-service"
        />
         <Banner
          header = "Terms of Service"
          imgBg = {bannerBg}
          pageName="Terms of Service"
          pageLink="/terms-of-service"
        />
        <div className="section content m12">
            <Grid container         
            rowSpacing={{ xs: 4, sm: 4, md: 6 }}
            columnSpacing={{ xs: 2, sm: 4, md: 6 }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} className="testimonial-item">
                <p><strong>1. Parties and Definitions To This Agreement</strong></p>
                <p><strong>“SB 2 Build” </strong>for the purposes of this agreement, shall mean SB 2 Build
                    Limited. Any person or entity acting for or on behalf of SB 2 Build or
                    with the permission or authority of SB 2 Build.</p>
                <p> <strong>“Customer”</strong> shall mean the Customer, any person or entity acting for or
                    on behalf of the Customer or with the permission or authority of the
                    Customer as detailed on any quotation, estimate, work authority,
                    notation or form as provided by SB 2 Build to the Customer.</p>
                <p><strong>“Guarantor”</strong> shall mean any person (or persons), or entity, who agrees
                    to be held liable for the debts incurred by the Customer in the course of
                    business between the Customer and SB 2 Build on a principal debtor
                    basis.</p>
                <p><strong>“Services”</strong> shall mean all services supplied by SB 2 Build to the Customer
                    and includes any recommendations or consultancy advice.</p>
                <p><strong>“Price”</strong> shall mean the price payable for the goods and or service as
                    agreed between SB 2 Build and the Customer in accordance with clause
                    3 of this contract.</p>
                <p><strong>2. Goods And Services</strong></p>
                  <p>The Goods and Services provided shall be described on our invoices,
                  quotation, and/or work authorisation, or any other such form as
                  provided by SB 2 Build to the Customer.</p>
                <p><strong>3. Price And Payment</strong></p>  
                   <ul> The price shall be at SB 2 Build’s sole discretion, one or more of the
                    following:
                    <li> The price as quoted to the customer or as per SB 2 Build current
                    pricing schedule as at the time of the delivery of the order.</li>
                    <li>The price may be increased by the amount of any increase or
                    decrease in the cost of any items (including any change in
                    currency exchange rates) affecting the cost of supply, production
                    and/or delivery of the Goods between the date of SB 2 Build’s
                    acceptance of the Customer’s order and the date of delivery.</li>
                    <li>SB 2 Build’s price as specified on SB 2 Build’s current price list
                    and/or hourly rate as when the goods and/or service is provided.</li> 
                    <li>Alterations to any price list shall be effective from the date
                    specified by SB 2 Build at the time of giving notice to the
                    Customer.</li>
                    <p>Any change or variation to the specified work, design, plan or service
                    provided, will be charged on the same rates as per SB 2 Build’s quoted
                    price. Charges for the variation to the quoted work will be invoiced as a
                    variation to the quotation or agreed work and shall be payable
                    immediately on the completion of the work.</p>
                    </ul> 
                   <p><strong>4. Payment Terms</strong></p> 
                        <ul>Payment shall be at SB 2 Build’s sole discretion one or more of the
                        following:
                        <li>At SB 2 Build’s sole discretion a 50% deposit shall be
                        payable by the Customer on the placement of any
                        order for Goods or Services unless otherwise agreed
                        between SB 2 Build and the Customer.</li>
                        <li>Payment to approved customers shall be made by
                        instalments in accordance with SB 2 Build’s payment
                        schedule.</li>
                        <li>SB 2 Build shall provide the Customer with an invoice
                        and monthly statement. These invoices are due for
                        payment 7 days following delivery invoice date (or
                        other such date as stated on the invoice).</li>
                        <p>Payments will be made as agreed between SB 2 Build and the Customer.
                        If no payment arrangement is made or payment terms agreed then
                        payment shall be due as stated on the Invoice in cash, cheque, or by, or
                        by direct credit to SB 2 Build’s nominated account.</p>

                        <p>Payment shall not be deemed to have been received unless the payment
                        is made in cash or cleared funds are deposited in SB 2 Build’s nominated
                        account. Any other form of payment will not be receipted as paid until
                        the transaction is deemed to be honoured.</p>
                        <p>GST and other taxes and duties that may apply will be added to the Price
                        unless they are expressly included in the Price.</p>
                        <p>SB 2 Build may impose a credit limit at its discretion, and alter the credit
                        limit without notice. Where the credit limit is exceeded, SB 2 Build
                        reserves the right to refuse to supply Goods to the Buyer.</p>
                        
                        </ul>

                        <p><strong>5. Acceptance Of Terms Of Trade</strong></p>
                    <p>Any engagement of SB 2 Build’s Services including the supply of goods or
                    Services to the Customer shall constitute acceptance of the Terms and
                    Conditions of Trade of SB 2 Build by the Customer. Should more than
                    one Customer enter into this agreement the Customers shall be jointly
                    and severally liable for payment in full of the Price.</p>
                    <p>The Customer must be either the rightful legal Customer or have the full
                    authority of the legal Customer of the Property or Land to enter into a
                    contract or provide instructions to SB 2 Build to undertake work or
                    provide goods or services.</p>
                    <p>The Terms and conditions of this agreement can only be amended with
                    the written consent of SB 2 Build and shall be binding on the Customer.
                    In the event that the Customer proposes any change to the structure of
                    the Customers business, a change in Shareholding, Name, Directors,
                    Premises, postal address, registered office or Sale of the business the
                    Customer shall give no less than fourteen one (14) days written notice of
                    the proposed change or changes. If any loss is incurred by SB 2 Build the
                    Customer shall be liable for any loss suffered by SB 2 Build due to the
                    Customer not complying with this provision.</p>
                    <p><strong>6. Title to Goods</strong></p>
                    <ul>
                        Ownership and Title of any goods supplied by SB 2 Build to the
                        Customer shall not take effect until:
                        <li>The Customer has paid SB 2 Build all invoiced amounts owing for
                        the Goods and fulfilled all obligations to SB 2 Build in relation to
                        this agreements.</li>
                       <li>Any form of payment made by the Customer to SB 2 Build other
                        than cash shall not be deemed to be payment until that form of
                        payment has been cleared in accordance with clause 4.7 and
                        until then SB 2 Build’s rights and Ownership in relation to the
                        Goods shall continue. It is also agreed that:</li>
                       <li>Once an order for Goods or provision of Services is placed, no
                        order may be withdrawn, revoked or cancelled without SB 2
                        Build’s written consent.</li>
                        <li>If any part of the goods shall become incorporated into land so as
                        to lose its separate identity then the title of that proportion of
                        the goods so integrated equal in value to the price owed to SB 2
                        Build shall be reserved and vested in SB 2 Build until all money
                        the Customer owes to SB 2 Build has been paid in full.</li>
                        <p>While ownership of the Goods remains with SB 2 Build:</p>
                        <ul>
                            <li>The Buyer will store them separately and clearly identify them as
                            SB 2 Build’s goods.</li>
                            <li>SB 2 Build authorises the Buyer in the ordinary course of its
                            business (but not otherwise) to use the Goods or sell them for
                            full consideration. This authority will be deemed to be revoked
                            from the time that an event of default occurs, or SB 2 Build
                            notifies the Buyer in writing that this authority is revoked.</li>
                           <li>SB 2 Build or its agent may enter (as the Buyer’s agent) the
                            premises where the Goods are situated and remove them,
                            without being responsible for any damage caused thereby. SB 2
                            Build may resell any of the Goods and apply the proceeds of sale
                            in reduction of the Buyer’s Indebtedness.</li>
                           <li>The Buyer must advise SB 2 Build immediately of any Event of
                            Default or any action by third parties (including any creditors)
                            affecting SB 2 Build’s interest in the Goods.</li>
                        </ul>
                        
                        <p>SB 2 Build may apply any payments received from or on behalf of the
                        Buyer in reduction of the Buyer’s Indebtedness as SB 2 Build thinks fit.
                        If the Buyer resells or uses any Goods before ownership of them has
                        passed to the Buyer, the proceeds of such sale or use shall be received
                        and held by the Buyer (in whatever form) in trust for both the Buyer and
                        SB 2 Build. The interest of SB 2 Build as beneficiary under that Trust shall
                        be the portion of the proceeds of sale or other dealing in respect of the
                        Goods which does not exceed the Buyer’s Indebtedness to SB 2 Build.</p>
                       <p> SB 2 Build may bring an action for the price of the Goods sold
                        notwithstanding that ownership of the Goods may not have passed to
                        the Buyer.</p>
                    </ul>
                    <p><strong>7. Indemnity from Claims</strong></p>
                    <p>The Customer warrants that no instruction, Plan, Design or drawing
                    provided to SB 2 Build will be misleading, Infringe or result in the
                    infringement of any copyright and or any intellectual property right of
                    any person, and/or result in the breach of any law, statute, regulation or
                    ordinance.</p>

                    <p><strong>8. Defects</strong></p>
                    <p>The Customer shall inspect the Goods and Services provided by SB 2
                    Build and shall within fourteen (14) days of delivery (time being of the
                    essence) notify SB 2 Build of any alleged defect, damage or failure to
                    comply with the description or quote. The Customer shall afford SB 2
                    Build an opportunity to inspect the goods or services provided within a
                    reasonable time following completion of the service provided or delivery
                    of goods if the Customer believes the Goods or Services to be defective
                    in any way. If the Customer shall fail to comply with these provisions the
                    completed works shall be presumed to be free from any defect. For
                    defective Works, SB 2 Build’s liability is limited to undertaking remedial
                    work on the agreed fault or repair or replacement of any faulty product.</p>

                    <p><strong>9. Damages</strong></p>
                      <p>SB 2 Build shall not be liable for any loss or damage caused in accessing
                      the work site beyond reasonable control of SB 2 Build.</p>

                    <p><strong>10. Compliance with Laws</strong></p>
                      <p>The Customer and SB 2 Build shall comply with the provisions of all
                      statutes, regulations and bylaws of government, local and other public
                      authorities that may be applicable to the Works.</p>
                        
                    <p>The Customer shall obtain (at the expense of the Customer) all licenses
                    and approvals that may be required for the Works unless otherwise
                    agreed in writing with SB 2 Build.</p>
                    <p>The Customer agrees that the site will comply with any occupational
                    health and safety laws relating to building/construction sites and any
                    other relevant safety standards or legislation.</p>

                    <p><strong>11. Insurance</strong></p>
                    <p> SB 2 Build shall maintain public liability insurance of at least
                    $1,000,000.00 indemnifying the Owner against claims arising from the
                    operations of SB 2 Build or subcontractor in relation to the works.</p>
                         
                    <p><strong>12. Customers Disclaimer</strong></p>
                    <p>The Customer hereby disclaims any right to rescind, or cancel any
                    contract with SB 2 Build or to sue for damages or to claim restitution
                    arising out of any inadvertent misrepresentation made to the Customer
                    by SB 2 Build and the Customer acknowledges that the Works are
                    bought relying solely upon the Customers skill and judgment.</p>

                    <p><strong>13. Intellectual Property</strong></p>
                    <p> Customer warrants that all designs or instructions to SB 2 Build will
                    not cause SB 2 Build to infringe any patent, registered design or
                    trademark in the execution of the Customer’s order and the Customer
                    agrees to indemnify SB 2 Build against any action taken by a third party
                    against SB 2 Build in respect of any such infringement.</p>

                    <p><strong>14. Personal Property Securities Act 1999 (“PPSA”)</strong></p>

                    <ul>Upon assenting to these terms and conditions in writing the Customer
                    acknowledges and agrees that:
                    <li>These terms and conditions constitute a security agreement for
                    the purposes of the PPSA; and</li>
                    <li>security interest is taken in all Goods previously supplied by SB 2
                    Build to the Customer (if any) and all Goods that will be supplied
                    in the future by SB 2 Build to the Customer.
                      <ul>
                      The Customer undertakes to:
                        <li>a. Sign any further documents and/or provide any further
                            information (such information to be complete, accurate and
                            up-to-date in all respects) which SB 2 Build may reasonably
                            require to register a financing statement or financing
                            change statement on the Personal Property Securities
                            Register;</li>
                        <li>b. Indemnify, and upon demand reimburse, SB 2 Build for all
                            expenses incurred in registering a financing statement or
                            financing change statement on the Personal Property 
                            Securities Register or releasing any Goods charged thereby;</li>
                        <li>c. Not register a financing change statement or a change
                            demand without the prior written consent of SB 2 Build; and</li>
                        <li> d. Immediately advise SB 2 Build of any material change in its
                              business practices of selling the Goods which would result in
                              a change in the nature of proceeds derived from such sales.</li>
                      </ul>
                    </li>
                        <p>SB 2 Build and the Customer agree that nothing in sections 114(1)(a),
                        133 and 134 of the PPSA shall apply to these terms and conditions. The
                        Customer waives its rights as a debtor under sections 116, 120(2), 121,
                        125, 126, 127, 129, 131 and 132 of the PPSA. Unless otherwise agreed to
                        in writing by SB 2 Build, the Customer waives its right to receive a
                        verification statement in accordance with section 148 of the PPSA. The
                        Customer shall unconditionally ratify any actions taken by SB 2 Build
                        under clause 15.</p>
                    </ul>
                    <p><strong>15. Warranty</strong></p>
                    <p>For Goods not supplied by SB 2 Build, the warranty shall be the current
                    warranty provided by the manufacturer of the Goods. SB 2 Build shall
                    not be responsible for or bound by any term, representation made, or </p>
                    
                    <p><strong>16. Default and Consequences of Non Payment</strong></p>
                      <p>If the Customer defaults in payment of any invoice when due, the
                      Customer shall pay all costs and disbursements incurred by SB 2 Build in
                      pursuing the debt including legal costs on a solicitor and own Customer
                      basis and SB 2 Build’s collection agency costs. Disbursements incurred by
                      SB 2 Build in pursuing the debt including legal costs on a solicitor and
                      own Customer basis and SB 2 Build’s collection agency costs.</p>

                     <p> Interest on overdue or unpaid invoices shall accrue from the date when
                      payment becomes due daily until the date payment is received at a rate
                      of 2% per calendar month and all interest shall compound monthly
                      before and after any judgement until payment is received in full.
                      SB 2 Build at his discretion may suspend or terminate the supply of
                      goods and/or services should the Customer, at any time be in breach of
                      any obligation to SB 2 Build (including those relating to payment). SB 2
                      Build will not be liable for any loss or damages the Customer has
                      deemed to have suffered because SB 2 Build has exercised his rights
                      under this clause.</p>
                      <p>If any account remains overdue after thirty (30)days then an amount of
                      $20.00 or 10.00% of the amount overdue (up to a maximum of $200)
                      whichever is the greater, shall be charged for administration fees and
                      shall become immediately due and payable.</p>
                      <ul>
                      Without prejudice to SB 2 Build other remedies at law, SB 2 Build shall
                      be entitled to cancel all or any part of any supply agreement with the
                      Customer which remains unfilled and all amounts owing to SB 2 Build
                      shall, whether or not due for payment, become immediately payable in
                      the event that:
                          <li>any money payable to SB 2 Build becomes overdue of payment,
                          or in SB 2 Build’s opinion the Customer will be unable to meet his
                          payments as they become due; or</li>
                          <li> the Customer becomes insolvent, convenes a meeting with its
                          creditors or proposes or enters into an arrangement with
                          creditors, or makes an assignment for the benefit of its creditors;
                          or</li>
                          <li>a receiver, liquidator, manager (provisional or otherwise) or
                            similar person is appointed in respect of the Customer or any
                            asset of the Customer.
                          </li>
                      </ul>

                     <p><strong>17. Right of Cancellation</strong></p> 
                      <p>SB 2 Build may cancel any contract to which these Terms and Conditions
                      apply or cancel the delivery of materials products or goods or Service at
                      any time before the materials, products or goods and alike are delivered
                      by giving written notice to the Customer. On giving notice SB 2 Build
                      shall repay to the Customer any sums paid in respect of the Price. SB 2
                      Build shall not be liable for any damages or losses arising from such
                      cancellation.</p>
                      <p>Should the Customer cancel any contract with SB 2 Build the Customer
                      shall be liable for any loss incurred by SB 2 Build (including but not
                      limited to loss of profits) up to the time of Cancellation.</p>

                      <p><strong>18. Security Agreement</strong></p>
                      <ul>
                          Despite anything to the contrary contained in these Terms and
                          Conditions or any other rights which SB 2 Build may have:
                          <li>Where the Customer and/or the Guarantor (if any) is the Owner
                          of land, realty, asset or property capable of being charged, the
                          Customer and/or Customers Guarantor agree to mortgage
                          and/or charge all or any of their joint and/or several interest in
                          the said land, realty, asset or property to SB 2 Build or SB 2 Build’s
                          nominee to secure all amounts and other monetary obligations
                          due and payable under these terms and conditions.</li> 
                          <li>The Customer and/or the Guarantor acknowledge and agree that</li>
                          <li>SB 2 Build (or SB 2 Build’s nominee) shall be entitled to lodge
                          where appropriate a caveat over the said land, realty asset or
                          property.</li>
                          <li>Once all payments and other monetary obligations payable to SB
                          2 Build hereunder have been met the caveat shall be removed.</li> 
                          <li>The Customer and/or Guarantor shall indemnify SB 2 Build
                          against all SB 2 Build’s costs, any disbursements and any legal
                          costs incurred on a solicitor Customer own basis in the event SB 2
                          Build elect to proceed in any manner in accordance with this
                          clause and/or its sub-clauses.</li> 
                         <li>The Customer and/or the Guarantor (if any) agree to irrevocably
                          appoint SB 2 Build’s nominated attorney as the Customer’s
                          and/or Guarantor’s true and lawful attorney to perform all
                          necessary acts to give effect to the provisions of this clause.</li> 
                          <p>
                          In consideration of SB 2 Build performing its obligations under this
                          Contract, the Buyer covenants to execute and provide forthwith upon
                          demand in favour of SB 2 Build a valid mortgage in the most current
                          Auckland District Law Society form, prepared at the cost of the Buyer,
                          and a Client Authority and Instruction Form for an electronic transaction
                          (in the current form as approved by the New Zealand Law Society (NZLS)
                          and Registrar-General of Land) to enable the registration of a mortgage
                          by e-dealing on the Landonline system at Land Information New Zealand
                          (LINZ) over the Property to secure performance of any obligations owed
                          by the Buyer to SB 2 Build under this Contract.</p>
                      </ul> 

                    <p><strong>19. Privacy Act 1993</strong></p>
                    <p>The Customer and the Guarantor/s (if separate to the Customer)
                    authorises SB 2 Build to: collect, use and retain any information about
                    the Customer, for the purpose of assessing the Customer’s
                    creditworthiness or marketing products and services to the Customer;
                    and disclose information about the Customer, whether collected by SB 2
                    Build from the Customer directly or obtained by SB 2 Build from any
                    other party, to any other credit provider or any credit reporting agency
                    for the purposes of providing or obtaining a credit reference, debt
                    collection or notifying a default by the Customer.</p>
                    <p> Where the Customer and/or Guarantors are an individual the authorities
                    under clause 19.1 are authorities or consents for the purposes of the
                    Privacy Act 1993.</p>
                    <p>The Customer and/or Guarantors shall have the right to request SB 2
                    Build for a copy of the information about the Customer and/or
                    Guarantors retained by SB 2 Build and the right to request SB 2 Build to
                    correct any incorrect information about the Customer and/or
                    Guarantors held by SB 2 Build.</p>

                    <p><strong>20. The Construction Contracts Act 2002</strong></p>
                    <ul>
                        The Customer hereby expressly acknowledges that by signing these
                        terms the work contemplated is under $30,000.00 plus GST and:
                        <li> 
                          <ul>SB 2 Build has the right to suspend work within five (5) working
                              days of written notice of his intent to do so if a payment claim is
                              served on the Customer, and:
                            <li>the payment is not paid in full by the due date for
                            payment and no payment schedule has been given by
                            the Customer; or</li>
                            <li>a scheduled amount stated in a payment schedule
                            issued by the Customer in relation to the payment
                            claim is not paid in full by the due date for its payment;
                            or</li>
                            <li>the Customer has not complied with an adjudicator’s
                            notice that the Customer must pay an amount to SB 2
                            Build by a particular date; and:</li> 
                            <li>SB 2 Build has given written notice to the Customer of
                            his intention to suspend the carrying out of
                            construction work under the construction contract.</li>
                            </ul>
                        </li>
                        <li>
                            If SB 2 Build suspends work, and they:
                            <ul>
                              <li>is not in breach of contract; and</li>
                              <li>is not liable for any loss or damage whatsoever
                              suffered, or alleged to be suffered, by the Customer or
                              by any person claiming through the Customer; and</li>
                              <li> is entitled to an extension of time to complete the
                              contract; and</li>
                              <li>Keeps his rights under the contract including the right
                              to terminate the contract; and may at any time lift the
                              suspension, even if the amount has not been paid or an
                              adjudicator’s determination has not been complied
                              with.</li>
                            </ul>
                        </li>
                        <li>
                        <ul>
                        if SB 2 Build exercises the right to suspend work, the exercise of
                        that right does not:
                            <li>i. affect any rights that would otherwise have been
                        available to SB 2 Build under the Contractual Remedies
                        Act 1979; or:</li>
                        </ul>                               
                        </li> 
                        Enable the customer to exercise any rights that may otherwise have
                        been available to the customer under the Act as a direct consequence of
                        SB 2 Build suspending work under this provision
                    </ul>
                    <p><strong>21. Risk</strong></p>
                    <p>
                    If SB 2 Build retains Ownership of the Goods nonetheless, all risk for the
                    Goods passes to the Customer when the goods are delivered.
                    </p>
                    <p>
                    If any of the Goods are damaged or destroyed following delivery but
                    prior to Ownership passing to the Customer, SB 2 Build is entitled to
                    receive all insurance proceeds payable for the Goods The production of
                    these terms and conditions by SB 2 Build is sufficient evidence of SB 2
                    Build’s rights to receive the insurance proceeds without the need for any
                    person dealing with SB 2 Build to make further enquiries.
                    </p>

                    
                    <p><strong>22. Delivery</strong></p>
                        <p>At SB 2 Build’s sole discretion delivery of the Goods shall take place
                        when:</p>
                        <p>
                        Delivery of the Goods from a supplier to SB 2 Build or a third party
                        nominated by the Customer is deemed to be delivery to the Customer
                        for the purposes of this agreement.
                        </p>
                        <p>
                        Delivery of the Goods from SB 2 Build to the Customer, at the Customers
                        nominated address or nominated third party is deemed to be delivery to
                        the Customer for the purposes of this agreement.
                        </p>
                        <p>
                        The failure of SB 2 Build to deliver shall not entitle either party to treat
                        this contract as repudiated.
                        </p>
                        <p>
                        SB 2 Build shall not be liable for any loss or damage whatsoever due to
                        failure by SB 2 Build to deliver the Goods (or any of them) promptly or at
                        all, where due to circumstances beyond the control of SB 2 Build.
                        </p>
                        

                       <p><strong>23. Dispute Resolution</strong></p> 
                        <p>
                          All disputes and differences between the Customer and SB 2 Build
                          touching and concerning this agreement shall be referred to arbitration
                          under a single arbitrator agreed upon by both parties, or failing
                          agreement, by two arbitrators (one to be appointed by each party) and
                          their umpire (appointed by them prior to arbitration), such arbitration to
                          be carried out in accordance with provisions of the Arbitration Act 1996
                        </p>
                        <p><strong>24. General</strong></p>
                        <p>
                        If any provision of these terms and conditions shall be invalid, void,
                        illegal or unenforceable the existence, validity legality and enforceability
                        of the remaining provisions shall not be prejudiced, affected or impaired.
                        These terms and conditions and any contract to which they apply shall
                        be governed by the laws of New Zealand and are subject to the
                        jurisdiction of the courts of Nelson New Zealand or as otherwise directed
                        by the Court. All costs of enforcement or attempted enforcement by SB
                        2 Build including solicitor client costs on an indemnity basis and any
                        collection agency costs or charges shall be met by the Customer and/or
                        Guarantor.
                        </p>
                        <p>SB 2 Build shall be under no liability whatever to the Customer for any
                        indirect loss and/or expense (including loss of profit) suffered by the
                        Customer arising out of a breach by SB 2 Build of these terms and
                        conditions.</p>

                        <p>In the event of any breach of this contract by SB 2 Build the remedies of
                        the Customer shall be limited to damages which under no circumstances
                        shall exceed the contract price for Services provided and limited to the
                        amount of monies paid to SB 2 Build by the Customer in Part or Full
                        whichever is the lesser amount.</p>

                        <p>The Customer shall not be entitled to set off against or deduct from the
                        Price any sums owed or claimed to be owed to the Customer by SB 2
                        Build.</p>
                        <p>
                        SB 2 Build may license or sub-contract all or any part of his rights and
                        obligations without the Customer’s consent.
                        </p>
                        <p>
                          SB 2 Build reserves the right to review these terms and conditions at any
                          time. If, following any such review, there is to be any change to these
                          terms and conditions, then that change will take effect from the date on
                          which SB 2 Build notifies the Customer of such change.
                          The provisions of the Contractual Remedies Act 1979 shall apply to this
                          contract as if section 15(d) were omitted from the Contractual Remedies
                          Act 1979.
                        </p>
                        
                        <p>
                        Neither party shall be liable for any default due to any act of God, war,
                        terrorism, strike, lock-out, industrial action, fire, flood, drought, storm or
                        other event beyond the reasonable control of either party.
                        </p>
                        <p>
                        The failure by SB 2 Build to enforce any provision of these terms and
                        conditions shall not be treated as a waiver of that provision, nor shall it
                        affect SB 2 Build’s right to subsequently enforce that provision.
                        </p>
                        <p>
                        The failure by SB 2 Build to enforce any provision of these terms and
                        conditions shall not be treated as a waiver of that provision, nor shall it
                        affect SB 2 Build’s right to subsequently enforce that provision.

                        </p>
   
              </Grid>
            </Grid>
        </div>
        
    </div>
  )
}
