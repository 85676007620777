import React from 'react'
import { Link } from "react-router-dom";
import {BsArrowRightShort, BsCheckCircleFill} from "react-icons/bs";
import Grid from "@mui/material/Grid";
import {LazyLoadImage} from "react-lazy-load-image-component";
import grandImg from "../assets/images/scp-img-granddesign.webp";
import newImg from "../assets/images/New_build.webp";
import renovationImg from "../assets/images/Extension.webp";
import "../assets/css/services.scss";

function CommercialServices(props) {
    return (
        <div className="section builds gray" id="commercial-services">
            <div className="container">
                <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={3}
                    className="builds-wrap"
                    data-aos="fade"
                >
                    <Grid item xs={12} sm={6} md={3} className="build">
                        <Link to="/commercial/light-commercial-builder-dunedin" className="link-wrap">
                            <h3>Light Commercial</h3>
                            <p>
                                Looking for a trusted commercial builder to work on your projects in Otago? SB2 Build has experience in urban and rural builds and specialist projects including earthquake strengthening.
                            </p>
                            <LazyLoadImage
                                src={grandImg}
                                width="100px"
                                height="100px"
                                sizes="(max-width: 500px) 500px, 800px"
                                alt="Grand designs"
                            />
                            <span className="redButton btn link">Learn more <BsArrowRightShort/></span>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} className="build">
                        <Link to="/commercial/structural-upgrades-dunedin" className="link-wrap">
                            <h3>Structural Upgrades</h3>
                            <p>
                                Seismic strengthening involves upgrading the structural elements of a building to withstand the forces generated by earthquakes. This process aims to prevent collapse, minimize damage, and ensure the safety of occupants during and after seismic events.
                            </p>
                            <LazyLoadImage
                                src={newImg}
                                width="100px"
                                height="100px"
                                sizes="(max-width: 500px) 500px, 800px"
                                alt="New build"
                            />
                            <span className="redButton btn link">Learn more <BsArrowRightShort/></span>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

export default CommercialServices